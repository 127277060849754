// @ts-strict-ignore
/* istanbul ignore file */
import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import ButtonView from 'ckeditor5/packages/ckeditor5-ui/src/button/buttonview';
import Command from 'ckeditor5/packages/ckeditor5-core/src/command';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-pdf-export.svg';
import { setShowModal } from '@/reportEditor/pdfExport.actions';
import i18next from 'i18next';

export const PDF_EXPORT_COMMAND = 'seeqPdfExport';

/**
 * This adds a button which open the pdf export modal, allowing the user to export the current document as a pdf.
 */
export class PdfExport extends Plugin {
  static pluginName = 'PdfExport';
  static setup = {
    name: PdfExport.pluginName,
    plugin: PdfExport,
    toolbar: PdfExport.pluginName,
  };

  init() {
    this.editor.commands.add(PDF_EXPORT_COMMAND, new PdfExportCommand(this.editor));
    this.defineToolbarButton();
  }

  defineToolbarButton() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    editor.ui.componentFactory.add(PdfExport.pluginName, (locale) => {
      const command = editor.commands.get(PDF_EXPORT_COMMAND);
      const buttonView = new ButtonView(locale);

      buttonView.set({
        icon,
        tooltip: i18next.t('REPORT.EDITOR.SAVE_AS_PDF_BUTTON_TITLE'),
        tooltipPosition: 'sw',
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => editor.execute(PDF_EXPORT_COMMAND));

      return buttonView;
    });
  }
}

class PdfExportCommand extends Command {
  execute() {
    setShowModal(true);
  }
}
