import { SeeqNames } from '@/main/app.constants.seeqnames';
import { CONTENT_LOADING_CLASS } from '@/reportEditor/report.constants';
import { sqWorkbookStore } from '@/core/core.stores';

export const IGNORE_CK_PAGINATION_VIEW_SELECTOR = 'div#journalEditor ';

/**
 * Returns a Node list object of all pieces of Seeq content that are currently in an error state.
 *
 * @returns {JQuery<TElement extends Node>}
 */
export function getContentInErrorState() {
  return document.querySelectorAll(
    `${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].${CONTENT_LOADING_CLASS.ERROR}`,
  );
}

/**
 * Returns a Node list object of all pieces of Seeq content that are currently loading.
 *
 * @returns {NodeListOf<Element>} object of all Seeq content elements that are still loading
 */
export function getLoadingContent(): NodeListOf<Element> {
  return document.querySelectorAll(
    `${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]:not(.${CONTENT_LOADING_CLASS.LOADED}):not(.${CONTENT_LOADING_CLASS.ERROR}):not(.${CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR})`,
  );
}

/**
 * Returns a Node list object of all pieces of Seeq content, regardless of their state.
 *
 * @returns {NodeListOf<Element>} object of all Seeq content elements
 */
export function getAllContent(): NodeListOf<Element> {
  return document.querySelectorAll(
    `${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`,
  );
}

/**
 * Returns the status of all Seeq content in the document.
 *
 * @returns containing a key value pair for each state, where the keys are loaded,
 *  inProgress, and failed, and the values are the count of pieces of Seeq content in that state.
 */
export function getAllContentStatus(): {
  failed: number;
  inProgress: number;
  loaded: number;
} {
  const failed = getContentInErrorState().length;
  const inProgress = getLoadingContent().length;
  const loaded = getAllContent().length - failed - inProgress;
  return { failed, inProgress, loaded };
}

/**
 *  Returns false if any content in the browser is still being loaded, otherwise true. Used by the screenshot service to
 *  determine when the document is ready to be captured. To ensure the content is fully loaded there are three
 *  conditions that must be met:
 *  - CK must be finished loading. Without this check the page will be empty as it loads which could lead to a
 *  false-positive if it takes a while for CK to load.
 *  - There can be no instances of an empty reactWrapper because that indicates an inner component has not yet
 *  finished rendering.
 *  - Finally, all Content must either be in a loaded or failed (considered ok for the purposes of this check) state.
 *
 * @returns {boolean} indicating whether all content has finished loading
 */
export function isAllContentFinishedLoading() {
  return (
    (!sqWorkbookStore.isReportBinder || isCKEditorLoaded()) &&
    document.querySelectorAll(`.${CONTENT_LOADING_CLASS.REACT_WRAPPER}:empty`).length === 0 &&
    getLoadingContent().length === 0
  );
}

let isCKLoaded = false;

export const isCKEditorLoaded = () => isCKLoaded;

export const setCKEditorLoaded = (isLoaded: boolean) => {
  isCKLoaded = isLoaded;
};
