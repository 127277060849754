import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CONTENT_STATE } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { InsertTemplateModal } from '@/reportEditor/components/InsertTemplateModal.molecule';
import { setShowModal } from '@/core/hooks/useModalManager.hook';
import { executeCommand } from '@/utilities/reportEditor.utilities';
import { clearReportContent, setModalName } from '@/reportEditor/reportContent.actions';

interface InsertContentDropdownProps {
  onClick?: () => void;
}

export const InsertContentDropdown: React.FunctionComponent<InsertContentDropdownProps> = ({ onClick }) => {
  const { t } = useTranslation();

  const options = [
    {
      action: 'worksheet',
      title: 'REPORT.EDITOR.CHOOSE_CONTENT',
      description: 'REPORT.EDITOR.CHOOSE_CONTENT_DESCRIPTION',
    },
  ];

  options.push(
    {
      action: 'date_range_label',
      title: 'REPORT.EDITOR.DATE_RANGE_LABEL',
      description: 'REPORT.EDITOR.DATE_RANGE_LABEL_DESCRIPTION',
    },
    {
      action: 'asset_label',
      title: 'REPORT.EDITOR.ASSET_LABEL',
      description: 'REPORT.EDITOR.ASSET_LABEL_DESCRIPTION',
    },
    {
      action: 'template',
      title: 'REPORT.EDITOR.TEMPLATE',
      description: 'REPORT.EDITOR.TEMPLATE_DESCRIPTION',
    },
  );

  const onOptionClick = (option: string) => {
    switch (option) {
      case 'template':
        doTrack('Topic', 'Seeq Link - Template', 'displayed');
        clearReportContent();
        setShowModal(InsertTemplateModal as React.FC, true);
        break;
      case 'worksheet':
        doTrack('Topic', 'Seeq Link - Worksheet', 'displayed');
        clearReportContent();
        setModalName(CONTENT_STATE.WORKBOOK);
        break;
      case 'date_range_label':
        doTrack('Topic', 'Seeq Link - Date Range Label', 'displayed');
        executeCommand({ command: 'date_range_label' });
        break;
      case 'asset_label':
        doTrack('Topic', 'Seeq Link - Asset Label', 'displayed');
        executeCommand({ command: 'asset_label' });
        break;
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="flexNoGrowNoShrink flexRowContainer">
      {_.map(options, (option) => (
        <div
          key={option.action}
          className={classNames('seeqContentDropdown fr-command', option.action)}
          onClick={() => onOptionClick(option.action)}>
          {t(option.title)}
          <div>{t(option.description)}</div>
        </div>
      ))}
    </div>
  );
};
