// @ts-strict-ignore
/* istanbul ignore file */
import React from 'react';
import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';
import ArrowlessDropdownButton from '@/annotation/ckEditorPlugins/views/ArrowlessDropdownButton';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-link.svg';
import { elementWithRoot, toggleCkResetClass } from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { createDropdown } from 'ckeditor5/packages/ckeditor5-ui/src/dropdown/utils';
import { JournalLink as JournalLinkMolecule } from '@/annotation/JournalLink.molecule';
import { CK_DROPDOWN_BUTTON_CLASS } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import i18next from 'i18next';
import Command from 'ckeditor5/packages/ckeditor5-core/src/command';

export const COMMAND_NAME = 'journal_link';

export class JournalLink extends Plugin {
  static pluginName = 'JournalLink';
  static setup = {
    name: JournalLink.pluginName,
    plugin: JournalLink,
    toolbar: JournalLink.pluginName,
  };

  init() {
    this.defineToolbarButton();
    this.editor.commands.add(COMMAND_NAME, new JournalLinkCommand(this.editor));
  }

  defineToolbarButton() {
    let dropdown;
    const editor = (this as any).editor;
    const onLink = (link) => {
      editor.execute(COMMAND_NAME, link);
      dropdown.panelView.set('isVisible', false);
    };

    editor.ui.componentFactory.add(JournalLink.pluginName, (locale) => {
      dropdown = createDropdown(locale, ArrowlessDropdownButton);
      const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

      dropdown.buttonView.set({
        label: i18next.t('REPORT.EDITOR.CONTENT_BUTTON_TITLE'),
        icon,
        tooltipPosition: 'se',
        tooltip: true,
        class: 'contentBtn seeqLinkButton',
      });

      dropdown.panelView.on('render', (eventInfo) => {
        elementWithRoot(deps, eventInfo.source.element, <JournalLinkMolecule onLink={onLink} />);
      });

      dropdown.panelView.on('change:isVisible', () => toggleCkResetClass());

      dropdown.class = CK_DROPDOWN_BUTTON_CLASS;

      return dropdown;
    });
  }
}

export class JournalLinkCommand extends Command {
  execute(link = '') {
    const editor = (this as any).editor;
    if (editor.model.document.selection.isCollapsed) {
      const viewFragment = editor.data.processor.toView(link);
      const modelFragment = editor.data.toModel(viewFragment);
      editor.model.insertContent(modelFragment);
    } else {
      editor.execute('link', link.match('href="(.*)">')[1]);
    }
  }
}
