// @ts-strict-ignore
import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';
import ButtonView from 'ckeditor5/packages/ckeditor5-ui/src/button/buttonview';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-fixed-width.svg';
import i18next from 'i18next';

export class FixedWidth extends Plugin {
  [editor: string]: any;

  static setup = {
    name: 'FixedWidth',
    plugin: FixedWidth,
    toolbar: 'FixedWidth',
  };

  init() {
    this.defineToolbar();
  }

  defineToolbar() {
    const editor = this.editor;
    editor.ui.componentFactory.add('FixedWidth', (locale) => {
      const view = new ButtonView(locale);
      view.set({
        label: i18next.t('REPORT.EDITOR.FIXED_WIDTH'),
        icon,
        tooltip: true,
      });
      view.on('execute', () => {
        editor.fire('toggleFixedWidth');
      });
      return view;
    });
  }
}
