// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  getPropertyForContent,
  refreshHighchartsSize,
  SubVisualization,
  VisualizationContentProps,
  VisualizationContentPropsTransformer,
} from '@/annotation/ckEditorPlugins/components/content.utilities';
import { TableBuilderPropsOnly } from '@/tableBuilder/TableBuilderPropsOnly.organism';
import { sqContentApi } from '@/sdk';
import _ from 'lodash';
import { XyPlotContent } from '@/scatterPlot/XyPlotContent.organism';
import { ErrorBoundaryWithLogging } from '@/core/ErrorBoundary.atom';
import { forceRefreshContent } from '@/annotation/reportContent.utilities';
import { ErrorFallbackMinimal } from '@/core/ErrorFallback.organism';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { ChartViewContent } from '@/reportEditor/ChartViewContent.molecule';
import { CONTENT_LOADING_CLASS, IMAGE_BORDER_CLASS } from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';
import { EmptyContent } from '@/reportEditor/EmptyContent.molecule';
import { applyContentUpgrade } from '@/annotation/ckEditorPlugins/components/interactiveContentUpgrader.utilities';

const getAdaptedHeight = (
  visualization: Visualization,
  subVisualization: SubVisualization,
  height: number,
  parentElement: HTMLElement,
) => {
  if (!parentElement?.children[0]?.children) {
    return;
  }
  const siblings = _.slice(parentElement.children[0].children, 1);
  const totalHeight = _.reduce(
    siblings,
    (totalSoFar: number, element: HTMLElement) => {
      const elementStyle = window.getComputedStyle(element);
      const marginTop = parseFloat(elementStyle.getPropertyValue('margin-top'));
      const marginBottom = parseFloat(elementStyle.getPropertyValue('margin-bottom'));
      return totalSoFar + element.offsetHeight + marginTop + marginBottom;
    },
    0,
  );
  return height - totalHeight;
};

const renderComponent: (
  visualization: Visualization,
  props: any,
  stopUpdate: boolean,
  loading: boolean,
) => JSX.Element = (visualization: Visualization, props: any, stopUpdate: boolean, loading: boolean) => {
  const InnerComponent =
    {
      [Visualization.TABLE]: TableBuilderPropsOnly,
      [Visualization.XY_PLOT]: XyPlotContent,
      [Visualization.TREND]: ChartViewContent,
      [Visualization.NONE]: EmptyContent,
    }[visualization] || null;
  if (_.isNull(InnerComponent)) {
    throw new Error('No visualization type found in React JSON blob');
  }
  return (
    <div
      data-testid={`innerContainer-${props.contentId}`}
      className={classNames('inheritMinHeight flexRowContainer reactJsonContent', {
        contentWrapperStopUpdate: stopUpdate,
        tableVisualization: visualization === Visualization.TABLE && !props.showChartView,
      })}>
      <InnerComponent {...props} stopUpdate={stopUpdate} />
    </div>
  );
};

interface ReactJsonContentProps {
  contentId: string;
  style: any;
  loading: boolean;
  target: any;
  src: string;
  border?: boolean;
  onLoad: (properties: { visualization: Visualization }) => void;
  onError: (error: string, errorCode: number) => void;
  onMeasurementsReady: (width: number, height: number) => void;
  showMenu?: () => void;
  error?: string;
  extraClassNames: string;
  errorClass?: string;
  propertyOverrides?: any;
  stopUpdate?: boolean;
  height?: number;
  width?: number;
}

/**
 * Handles React JSON visualizations. The properties state object is expected to the props to a props only version of a
 * Analysis visualization.
 */
export const ReactJsonContent: React.FunctionComponent<ReactJsonContentProps> = ({
  contentId,
  extraClassNames,
  errorClass,
  style,
  loading,
  target,
  src,
  onLoad,
  onError,
  border,
  onMeasurementsReady,
  showMenu = () => null,
  error,
  propertyOverrides,
  stopUpdate = true,
  height = 0,
  width = 0,
}) => {
  const [properties, setProperties] = useState<any | undefined>();
  const isCurrentlyLoading = useRef(false);

  const updateHighchartContent = () => {
    if (!properties) {
      return;
    }
    refreshHighchartsSize(contentId, {
      visualization: properties.visualization,
      subVisualization: properties.subVisualization,
      height: getAdaptedHeight(
        properties.visualization,
        properties.subVisualization,
        height ? height : target.current.clientHeight,
        target.current,
      ),
      width: null,
    });
  };

  useEffect(() => {
    if (isCurrentlyLoading.current) {
      /**
       * at this point there's an ongoing getReactBlob call, so we shouldn't do a new one
       * this is usually the case when the browser doesn't have enough time between auto-updates
       * to re-render all the interactive content elements
       * we should also show some kind of warning that there's a lot of contents so we suggest increasing
       * the auto-update interval
       */
      return;
    }
    const queryParams = new URLSearchParams(src.substr(src.indexOf('?')));
    const params = {
      hash: queryParams.get('hash'),
    };
    const asyncDisabled = queryParams.get('useAsync') !== 'true';
    isCurrentlyLoading.current = true;
    sqContentApi
      .getReactBlob({ id: contentId }, { params, useManualAsync: true, asyncDisabled })
      .then(({ data }) => {
        const untypedProperties = data as any;
        if (untypedProperties.visualization) {
          const propertiesForVisualizationType = applyContentUpgrade(
            VisualizationContentPropsTransformer[untypedProperties.visualization]({
              ...untypedProperties,
              isContent: true,
              contentId,
              ...VisualizationContentProps[untypedProperties.visualization],
            }),
            untypedProperties.version ?? 0,
          );
          setProperties(propertiesForVisualizationType);
          onLoad(propertiesForVisualizationType);
        }
      })
      .catch((error) => {
        onError(error.data?.statusMessage, error.status);
      })
      .finally(() => {
        isCurrentlyLoading.current = false;
      });
  }, [src]);

  const measurementsCallback = useCallback(() => {
    const content = sqReportStore.getContentById(contentId);
    if (
      properties.visualization === Visualization.TABLE &&
      !getPropertyForContent('showChartView', propertyOverrides, properties)
    ) {
      onMeasurementsReady(0, 0);
    } else {
      onMeasurementsReady(content?.width ?? target.current.clientWidth, content?.height ?? target.current.clientHeight);
    }
  }, [properties, contentId]);

  useEffect(() => {
    if (!loading) {
      updateHighchartContent();
    }
  }, [width, height, properties, loading]);

  const getStyle = (errorClass: string) => {
    const width = errorClass && errorClass !== CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR ? 'fit-content' : 'inherit';

    const styleOverrides: any = {
      maxHeight: undefined,
      maxWidth: undefined,
      width,
      height: 'inherit',
    };

    return {
      ...style,
      ...styleOverrides,
    };
  };

  const retry = () => forceRefreshContent(contentId);

  return (
    <div
      data-testid={`content-${contentId}`}
      data-seeq-content={contentId}
      data-visualization={properties?.visualization ?? 'none'}
      data-sub-visualization={properties?.subVisualization ?? 'none'}
      style={getStyle(errorClass)}
      onClick={showMenu}
      ref={target}
      title={error}
      className={classNames(
        'inheritMinHeight',
        {
          [CONTENT_LOADING_CLASS.STOP_UPDATE]: stopUpdate,
          [IMAGE_BORDER_CLASS]: border,
        },
        extraClassNames,
        errorClass ?? '',
        {
          [CONTENT_LOADING_CLASS.SPINNER]: loading && !errorClass,
          [CONTENT_LOADING_CLASS.LOADED]: !loading && !errorClass,
        },
      )}>
      {!errorClass && properties && (
        <ErrorBoundaryWithLogging fallback={(error) => <ErrorFallbackMinimal error={error} retry={retry} />}>
          {renderComponent(
            properties.visualization,
            {
              ...properties,
              ...propertyOverrides,
              onContentLoad: measurementsCallback,
              afterChartUpdate: measurementsCallback,
            },
            stopUpdate,
            loading,
          )}
        </ErrorBoundaryWithLogging>
      )}
    </div>
  );
};
