/* istanbul ignore file */

import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';

/**
 * Allows configuration of Seeq specific dependencies for other plugins. Add additional dependencies to
 * `CKEditorPlugins.constants::BasePluginDependencies`
 */
export class PluginDependencies extends Plugin {
  static pluginName = 'PluginDependencies';

  init() {
    // Empty on purpose
  }
}
