// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/core/Icon.atom';
import { ViewMode } from '@/builder/builder.constants';
import { logError } from '@/utilities/logger';
import { formatApiError, switchLanguage } from '@/utilities/utilities';
import { sqWorkbenchStore } from '@/core/core.stores';
import { runBuilder } from '@/builder/builder.utilities';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchConfiguration } from '@/services/systemConfiguration.utilities';
import { setStateParams } from '@/workbench/workbench.actions';
import {
  getPresentWorksheetLink,
  getViewWorksheetLink,
  getWorksheetLink,
  setFromBuilder,
} from '@/main/routing.utilities';

export const Builder: React.FunctionComponent<{}> = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const searchParamsAsObject = Object.fromEntries(new URLSearchParams(searchParams)) as any;
  const trendItems = _.reject(searchParams.getAll('trendItems'), _.isEmpty);
  setStateParams({ ...params, ...searchParamsAsObject, trendItems });
  const stateParams = { ...params, ...searchParamsAsObject, trendItems };
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [buildError, setBuildError] = useState(null);

  // Sanitize the input
  const builderParams = {
    ...stateParams,
    viewMode: _.isString(stateParams.viewMode) ? (_.toLower(stateParams.viewMode) as ViewMode) : ViewMode.Edit,
    startFresh: _.isString(stateParams.startFresh) ? _.toLower(stateParams.startFresh) === 'true' : true,
  };

  useEffect(() => {
    setFromBuilder(true);
    fetchConfiguration()
      .then(() => switchLanguage(sqWorkbenchStore.userLanguage))
      .then(() =>
        runBuilder(builderParams)
          .then(({ workbookId, worksheetId }) => {
            if (builderParams.viewMode === ViewMode.View) {
              navigate(getViewWorksheetLink(workbookId, worksheetId));
            } else if (builderParams.viewMode === ViewMode.Present) {
              navigate(getPresentWorksheetLink(workbookId, worksheetId));
            } else {
              navigate(getWorksheetLink(workbookId, worksheetId));
            }
          })
          .catch((e) => {
            if (_.get(e, 'status') === HttpCodes.UNAUTHORIZED) {
              navigate(`/login?returnTo=${encodeURIComponent(window.location.href)}`);
            } else {
              logError(formatApiError(e));
              setBuildError(e);
            }
          }),
      );
  }, []);

  return (
    <div className="flexRowContainer flexCenter fullViewport splashScreen text-white">
      <Icon extraClassNames="fa-pulse fa-5x mb10" icon="fa-spinner" />
      <span className={classNames({ 'd-none': buildError })}>{t('LOADING')}</span>
      {buildError && (
        <p className="alert alert-danger">
          <span>{t('ERROR')}</span>
          {buildError.toString()}
        </p>
      )}
    </div>
  );
};
