// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { RefreshingContent } from '@/annotation/ckEditorPlugins/components/RefreshingContent.molecule';
import { HtmlPortalNode } from 'react-reverse-portal';
import {
  fetchContent as fetchReportContent,
  resetContentErrors,
  setContentDisplayMetadata,
} from '@/reportEditor/report.actions';
import {
  duplicateContentError,
  duplicateOrUnarchiveContent as utilitiesDuplicateOrUnarchiveContent,
  isAssetSelectionBeingCopied,
  isDateRangeBeingCopied,
} from '@/annotation/reportContent.utilities';
import {
  CONTENT_MODEL_ATTRIBUTES,
  ContentCallbacks,
  ContentDisplayMode,
  CustomPlugin,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { isContentInDocument } from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { useTranslation } from 'react-i18next';
import {
  AssetSelection,
  Content as ContentItem,
  CONTENT_LOADING_CLASS,
  DateRange,
} from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';
import { addObserverTarget, removeObserverTarget } from '@/core/intersectionObserverHub';
import { getGlobalInstance } from '@/utilities/reportEditor.utilities';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import _ from 'lodash';

interface ContentProps {
  // The below three props should be used in the initial render/useEffect. After that point, they may be out of
  // date due to duplication changing data behind the scenes. If additional functionality is added, make sure to use
  // `actualId` when referencing stores and the like.
  contentId: string;
  modelElement: any;
  portalNode: HtmlPortalNode;
  displayMode: ContentDisplayMode;
}

const MAX_TRIES = 100;

const minWidth = 100;
const maxWidth = 100;
const minHeight = 100;
const maxHeight = 100;
export const CONTENT_DATA_ID_PREFIX = 'content-wrapper-';

/**
 * Insertion in CKEditor in a synchronous process, so to copy or unarchive content we need to do it after the
 * component is inserted. This component either renders, duplicates, or unarchives the Content depending on the
 * below set of conditions.
 */
export const DuplicatingContent: React.FunctionComponent<ContentProps> = (props) => {
  const { contentId, modelElement, portalNode, displayMode } = props;

  const { t } = useTranslation();

  const editor: any = getGlobalInstance();
  let contentCallbacks: ContentCallbacks = {
    updateContentModelAndNode: _.noop,
    updateContentAttributeById: _.noop,
    getCurrentModel: _.noop,
    updateContentSize: _.noop,
    updateContentAttributeWithoutSaving: _.noop,
    setUseCkCopyLogic: _.noop,
  };
  if (editor && editor.config) {
    contentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;
  }

  // A = In store, B = Archived, C = has content display metadata (is visible)
  // !A, Content has been pasted from another document, needs duplication
  // A + B, Content has been cut and pasted, needs unarchival
  // A + !B + C, Content has been copied from and pasted to its current document, needs duplication
  // A + !B + !C, Content has not yet been displayed. Pass ID to actual Content component
  const canSkipDuplication =
    (sqReportStore.getContentById(contentId) &&
      !sqReportStore.getContentById(contentId)?.isArchived &&
      !isContentInDocument(contentId)) ||
    headlessRenderMode();
  const [error, setError] = useState(undefined);
  const [actualId, setActualId] = useState(canSkipDuplication && contentId);
  const [showRealContent, setShowRealContent] = useState(false);

  const duplicateOrUnarchiveContent = (content: ContentItem, dateRange: DateRange, assetSelection: AssetSelection) => {
    utilitiesDuplicateOrUnarchiveContent(content, isContentInDocument(contentId), dateRange, assetSelection)
      .then((content) => {
        if (content) {
          setContentDisplayMetadata({
            contentId: content.id,
            refresh: undefined,
            errorClass: undefined,
          });
          contentCallbacks.updateContentModelAndNode(
            editor,
            content.id,
            modelElement,
            portalNode,
            contentId !== content.id,
          );
          setActualId(content.id);
          setShowRealContent(true);
          resetContentErrors();
        }
      })
      .catch((error) => setError(duplicateContentError(contentId, error).errorClass));
  };

  const waitUntilDependenciesCopied = (
    content: ContentItem,
    dateRange: DateRange,
    assetSelection: AssetSelection,
    currentTry = 0,
  ) => {
    if (currentTry > MAX_TRIES) {
      errorToast({ messageKey: 'REPORT.CONTENT.DEPENDENCY_TIMEOUT' });
      setError(CONTENT_LOADING_CLASS.ERROR);
    } else {
      setTimeout(() => {
        if (isDateRangeBeingCopied(dateRange) || isAssetSelectionBeingCopied(assetSelection)) {
          waitUntilDependenciesCopied(content, dateRange, assetSelection, currentTry + 1);
        } else {
          duplicateOrUnarchiveContent(content, dateRange, assetSelection);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (actualId) {
      setContentDisplayMetadata({
        contentId: actualId,
        refresh: undefined,
        errorClass: undefined,
      });
      contentCallbacks.updateContentModelAndNode(editor, actualId, modelElement, portalNode);
      setShowRealContent(true);
    } else {
      contentCallbacks.updateContentAttributeWithoutSaving(CONTENT_MODEL_ATTRIBUTES.PENDING, contentId, modelElement);
      fetchReportContent(contentId, false)
        .then(({ content, dateRange, assetSelection }) => {
          if (isDateRangeBeingCopied(dateRange) || isAssetSelectionBeingCopied(assetSelection)) {
            waitUntilDependenciesCopied(content, dateRange, assetSelection);
          } else {
            duplicateOrUnarchiveContent(content, dateRange, assetSelection);
          }
        })
        .catch((error) => setError(duplicateContentError(contentId, error).errorClass));
    }
  }, []);

  // setup scroll check
  const [stopUpdate, setStopUpdate] = useState(displayMode !== ContentDisplayMode.PDF);
  const [contentWrapperElement, setContentWrapperElement] = useState<HTMLElement>(null);
  useEffect(() => {
    if (displayMode !== ContentDisplayMode.PDF && contentWrapperElement) {
      addObserverTarget(contentWrapperElement, setStopUpdate);
    }
  }, [actualId, contentWrapperElement]);
  useEffect(() => {
    return () => removeObserverTarget(contentWrapperElement);
  }, []);

  const displayClass = error ?? CONTENT_LOADING_CLASS.SPINNER;

  return actualId && showRealContent ? (
    <RefreshingContent
      contentId={actualId}
      editor={editor}
      updateContentSize={contentCallbacks.updateContentSize}
      displayMode={displayMode}
      stopUpdate={stopUpdate}
      wrapperLoadedCallback={(el) => setContentWrapperElement(el)}
    />
  ) : (
    <img
      data-testid={`${CONTENT_DATA_ID_PREFIX}${contentId}`}
      style={{ minHeight, minWidth, maxHeight, maxWidth }}
      className={displayClass}
      data-seeq-content={actualId}
    />
  );
};
