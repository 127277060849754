// @ts-strict-ignore
/* istanbul ignore file */
import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';
import PageBreak from 'ckeditor5/packages/ckeditor5-page-break/src/pagebreak';
import { BasePluginDependencies } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import Widget from 'ckeditor5/packages/ckeditor5-widget/src/widget';
import { toWidget } from 'ckeditor5/packages/ckeditor5-widget/src/utils';

// This lets catch CK page breaks and turn them into hr's that the PDF view can break open.
export class SeeqPageBreak extends Plugin {
  static pluginName = 'SeeqPageBreak';
  static setup = {
    name: SeeqPageBreak.pluginName,
    plugin: SeeqPageBreak,
  };

  static get requires() {
    return [PageBreak, Widget];
  }

  init() {
    this.defineConverters();
  }

  private defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    conversion.for('editingDowncast').elementToElement({
      model: 'pageBreak',
      converterPriority: 'highest',
      view: (modelElement, { writer: viewWriter }) => {
        if (!deps.isPDF) {
          return;
        }
        const div = viewWriter.createEditableElement('div', {
          style: 'page-break-after:always;',
          class: 'seeq-page-break',
        });

        return toWidget(div, viewWriter);
      },
    });
  }
}
